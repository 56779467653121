import type { QueryParams } from '~/types/api'
import type { CarouselSlide, CarouselSlidePagedResult } from '~/types/api'

export function createCarouselSlidesRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN_CAROUSEL = 'carousel-slides'

  async function getAll(params?: QueryParams) {
    if (!params) {
      params = {
        filter: APIFilters.makeFilter(getBaseFilter(true)),
        sort: APIFilters.makeSort({ weight: 'ASC' }),
      }
    }

    return get<CarouselSlidePagedResult>([DOMAIN_CAROUSEL], {
      params,
    })
  }

  async function getByNid(nid: number) {
    return get<CarouselSlide>([DOMAIN_CAROUSEL, nid.toString()])
  }

  return {
    getAll,
    getByNid,
  }
}
